<template>
  <div class="offer-processing-history">
    <history :histories="order_history" class="mt-4"></history>
  </div>
</template>

<script>

import OfferHeaderNavigation from "../../../components/offers-details/HeaderNavigation"
import History from "../../../components/history/History"
import ApiService from "../../../api";
import * as qs from "qs";

export default {
  name: "OfferProcessingHistory",
  components: {
    OfferHeaderNavigation,
    History
  },
  data() {
    return {
      order_history: [],
    }
  },

  created() {
    this.fetchOrderHistory()
  },
  computed: {
    orderHistoryRequestParams() {
      return {
        order: {
          'field': 'created_at',
          'direction': 'DESC'
        }
      }
    }
  },
  methods: {
    fetchOrderHistory() {
      const id = this.$route.params.id;
      ApiService.get(`order_history/${id}`, {
        params: this.orderHistoryRequestParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      }).then(response => {
        if (response.status !== 200) {
          return;
        }
        this.order_history = response.data.result.result;
      });
    }
  }
}
</script>

<style lang="scss">

</style>
